
import programs from '~/constants/programs';
import additions from '~/constants/additions';

export default {
  name: 'section-request',
  data() {
    return {
      selected: 1,
      isError: false,
      request: [
        'Каждую пятницу <strong>WELCOME DRINK</strong>', 
        'Программа от 6000 <strong>ВЕТОЧКА САКУРЫ</strong> в подарок',
        'Программа от 2 часов <strong>ПИП-ШОУ</strong> от мастера',
      ],
      req: {
        name: '',
        phone: '',
        master: null,
        program: null,
        adds: {},
      },
      isThanks: false,
      masterOptions: [
        {label: 'Лаура', value: 'laura'},
        {label: 'Саша', value: 'sasha'},
        {label: 'Диана', value: 'diana'},
        {label: 'Мия', value: 'mia'},
      ],
      programOptions: programs.map(program => {
        return {
          ...program,
          label: program.name.charAt(0).toUpperCase() + program.name.slice(1),
          value: program.id,
        };
      }),
      addOptions: additions.map(addition => {
        return {
          ...addition,
          label: addition.name,
        };
      }),
    };
  },
  computed: {
    filteredAddOptions() {
      if (!this.req.program) return [];
      return this.addOptions.filter(item => !this.req.program.exclude.includes(item.value));
    },
    selectedAdds() {
      if (!this.req.program) return [];
      return this.filteredAddOptions.filter(item => this.req.adds[item.value] === true);
    },
    totalPrice() {
      if (!this.req.program) return 0;
      return this.selectedAdds.reduce((total, add) => total + add.price, this.req.program.price);
    },
  },
  watch: {
    'req.phone': {
      handler(val) {
        if (this.isError && val.length >= 11) this.isError = false;
      },
    },
  },
  mounted() {
    this.$nuxt.$on('fill-request', (data) => this.fillRequest(data));
  },
  beforeDestroy() {
    this.$nuxt.$off('fill-request');
  },
  methods: {
    fillRequest(data) {
      this.$el.scrollIntoView();
      this.req.program = this.programOptions.find(opt => opt.value === data.program);
      this.filteredAddOptions.forEach(opt => {
        this.req.adds[opt.value] = data.adds.includes(opt.value);
      });
    },
    prevOffer() {
      if (this.selected !== 1) this.selected -= 1;
      else this.selected = 8;
    },
    nextOffer() {
      if (this.selected < 9) this.selected += 1;
      else this.selected = 1;
    },
    submit() {
      if (this.req.phone.length < 11) {
        this.isError = true;
        return;
      }
      this.$axios.post(`https://api.green-api.com/waInstance1101847346/sendMessage/df6ac5c755a7452ab29d1266a4ab342716de9cddea2d4cb0bd`, {
        chatId: '79189136655@c.us',
        message: this.createMessage(),
      }).then(res => {
        if (res.data.idMessage) this.isThanks = true;
      });
    },
    createMessage() {
      let mes = `Новая заявка с сайта 🤍\n\n`;
      if (this.req.name) mes += `${this.req.name}\n`;
      mes += `${this.req.phone}\n\n`;
      if (this.req.master) mes += `Мастер: ${this.req.master.label}\n`;
      if (this.req.program) mes += `Программа: ${this.req.program.label}`;
      if (this.selectedAdds.length) {
        this.selectedAdds.forEach(add => {
          mes += `\n☑️ ${add.name}`;
        });
      }
      mes += `\n\nСумма к оплате: ${this.totalPrice}`;
      return mes;
    },
  },
};
