
export default {
  name: 'rz-select',
  props: {
    placeholder: {
      type: [String, Number],
      default: 'Выбрать',
    },
    options: {
      type: Array,
      default: () => [],
    },
    value: {
      type: [Object, String, Number],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disabledProp: {
      type: Number,
      default: null,
    },
    labelProp: {
      type: String,
      default: 'label',
    },
    valueProp: {
      type: String,
      default: 'value',
    },
    validationRules: {
      type: Object,
      default: () => {},
    },
    errorText: {
      type: String,
      default: '',
    },
    transitionName: {
      type: String,
      default: 'fade',
    },
    multiple: Boolean,
    withoutLabel: Boolean,
    isError: Boolean,
    bgDefault: Boolean,
    withoutTabindex: Boolean,
    required: Boolean,
    big: Boolean,
    withPrice: Boolean,
  },
  data: () => ({
    isShowList: false,
    isMousedown: false,
    focusFlag: false,
    selectedValue: null,
    selectedItems: [],
    typeAheadPointer: -1,
  }),
  computed: {
    selectedCount() {
      if (this.multiple) {
        return this.selectedItems.reduce((memo, current) => {
          if (current) memo += 1;
          return memo;
        }, 0);
      }
      return 0;
    },
    title() {
      if (this.multiple && this.selectedCount) {
        return `Выбрано ${this.selectedCount}`;
      }
      return this.selectedValue && Object.keys(this.selectedValue).length && this.selectedValue.label
        ? this.getOptionLabel(this.selectedValue)
        : this.placeholder;
    },
    reversedOptions() {
      return [...this.options].reverse();
    },
    lastOptionIndex() {
      return this.options.length - 1;
    },
    selectedValueAny(){
      return this.selectedValue.value !== '';
    },
    isFilled() {
      return (this.selectedValue && Object.keys(this.selectedValue).length) && ((this.selectedValueAny && !this.multiple) || (this.multiple && this.selectedCount)) || this.selectedItems.value;
    },
  },
  watch: {
    value: {
      handler(newVal) {
        if (!this.multiple) {
          const index = this.options.findIndex(op =>
            this.isEqualOption(op, newVal)
          );
          this.onSelect(newVal, index, true);
        } else {
          this.options.forEach((opt ,index) => {
            this.selectedItems[index] = newVal[opt.value];
          });
        }
        this.selectedValue = newVal;
      },
      immediate: true,
      deep: true,
    },
    selectedValue: {
      handler(value) {
        this.$emit('input', value);
      },
    },
    isShowList: {
      handler(value) {
        if (value) setTimeout(() => this.scrollToSelected(), 100);
      },
    },
    selectedItems: {
      handler(value) {
        if (!this.multiple) return;
        const associatedItems = {};
        value.forEach((item, i) => {
          associatedItems[this.options[i].value] = item;
        });
        this.selectedValue = associatedItems;
      },
      deep: true,
    },
  },
  methods: {
    scrollToSelected() {
      if (this.$refs.selected && this.$refs.selected[0].offsetTop > this.$refs.dropdown.offsetHeight) {
        this.$refs.dropdown.scrollTop = this.$refs.selected[0].offsetTop;
      }
    },
    onSelect(option, index, withoutHideDropdown) {
      if (option && this.disabledProp !== index) {
        if (!this.multiple) this.selectedValue = option;
        this.typeAheadPointer = index;
        if (!withoutHideDropdown) this.hideDropdown();
        this.$emit('input', option, option[this.valueProp], index);
      } else if (option === null) {
        this.selectedValue = null;
      }
    },
    onEscape() {
      this.hideDropdown();
    },
    typeAheadUp() {
      if(!this.disabled){
        if (!this.isShowList) {
          this.isShowList = true;
        }
        if (this.typeAheadPointer > 0) {
          const nextPointer = this.typeAheadPointer - 1;
          const option = this.options[nextPointer];
          const isDisabled = option ? this.disabledProp === nextPointer || false : false;
          if (!isDisabled) {
            this.typeAheadPointer -= 1;
          } else {
            this.typeAheadPointer -= 1;
            this.typeAheadUp();
          }
        } else {
          const nextEnabledOption = this.reversedOptions.findIndex((o, index) =>
            this.disabledProp !== index
          );
          this.typeAheadPointer = this.lastOptionIndex - nextEnabledOption;
        }
      }
    },
    typeAheadDown() {
      if(!this.disabled){
        if (!this.isShowList) {
          this.isShowList = true;
        }
        if (this.typeAheadPointer < this.lastOptionIndex) {
          const nextPointer = this.typeAheadPointer + 1;
          const option = this.options[nextPointer];
          const isDisabled = option ? this.disabledProp === nextPointer || false : false;
          if (!isDisabled) {
            this.typeAheadPointer += 1;
          } else {
            this.typeAheadPointer += 1;
            this.typeAheadDown();
          }
        } else {
          this.typeAheadPointer = this.options.findIndex((o, index) =>
            this.disabledProp !== index
          );
        }
      }
    },
    typeAheadSelect() {
      if (this.options[this.typeAheadPointer]) {
        this.onSelect(
          this.options[this.typeAheadPointer],
          this.typeAheadPointer
        );
      }
      this.$emit('enter');
    },
    hideDropdown() {
      this.isShowList = false;
    },
    getOptionLabel(option) {
      if (typeof option === 'object') {
        return option[this.labelProp];
      }
      return option;
    },
    getOptionPrice(option) {
      if (typeof option === 'object') {
        return this.$options.filters.price(option.price);
      }
      return option;
    },
    isSelectedOption(option, index) {
      if (this.typeAheadPointer === -1 && this.selectedValue) {
        return this.isEqualOption(option, this.selectedValue);
      }
      return this.typeAheadPointer === index;
    },
    isEqualOption(a, b) {
      if (a && b && typeof a === 'object' && typeof b === 'object') {
        return (
          a[this.labelProp] === b[this.labelProp] &&
          a[this.valueProp] === b[this.valueProp]
        );
      }
      return a === b;
    },
    toggle() {
      if (this.disabled) return;
      this.isShowList = !this.isShowList;
    },
    mousedown() {
      this.isMousedown = true;
    },
    mouseup() {
      this.isMousedown = false;
      this.toggle();
    },
    focusin() {
      if (this.disabled || this.isMobile || this.isMousedown) return;
      this.isShowList = true;
    },
    focusout({relatedTarget}) {
      if (!this.disabled && !this.$refs.select.contains(relatedTarget)) {
        this.isShowList = false;
      }
    },
  },
};
