
export default {
  name: 'base-icon-drop-arrow',
  props: {
    width: {
      type: Number,
      default: 9.6,
    },
    height: {
      type: Number,
      default: 5.6,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    viewBox() {
      return `0 0 ${this.width} ${this.height}`;
    },
  },
};
