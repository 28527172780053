
export default {
  name: 'base-checkbox',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    small: Boolean,
    disabled: Boolean,
    flexStart: Boolean,
  },
  methods: {
    changed(ev) {
      this.$emit('input', ev.target.checked);
    },
    clickHandler() {
      if (this.disabled) return;
      this.$emit('click', this.value);
    },
  },
};
