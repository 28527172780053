
export default {
  name: 'base-input',
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    disabled: Boolean,
    value: {
      type: [String, Number],
      default: '',
    },
    isError: Boolean,
    errorText: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    required: Boolean,
    regexp: {
      type: RegExp,
      default: null,
    },
    pattern: {
      type: String,
      default: '',
    },
    inputmode: {
      type: String,
      default: '',
    },
    button: {
      type: Object,
      default: () => ({}),
    },
    autocomplete: {
      type: String,
      default: 'on',
    },
    name: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    isFocused: false,
    isAutofilled: false,
    randomId: null,
    currentType: 'text',
    isRegexpError: false,
    currentValue: '',
  }),
  computed: {
    classes() {
      return {
        'text-field--focused': this.isFocused,
        'text-field--filled': this.currentValue || typeof this.currentValue === 'number' || this.isAutofilled,
        'text-field--error': this.isError,
        'text-field--disabled': this.disabled,
        'text-field--mainColor': this.mainColor,
      };
    },
  },
  watch: {
    value: {
      handler(newVal) {
        this.$set(this, 'currentValue', newVal);
      },
      immediate: true,
    },
    currentValue: {
      handler(newVal) {
        this.$emit('input', newVal);
      },
    },
  },
  mounted() {
    this.randomId = String(Math.random());
    this.currentType = this.type;
  },
  methods: {
    focusin(e) {
      this.$emit('focusin', e);
    },
    inputFocus() {
      this.isFocused = true;
    },
    inputFocusOut() {
      this.isFocused = false;
      this.$emit('focusout');
    },
    enter(e) {
      e.preventDefault();
      e.stopPropagation();
      this.$emit('enter');
    },
    eyeClickHandler(e) {
      e.preventDefault();
  
      if (this.currentType === 'text') {
        this.currentType = this.type;
      } else {
        this.currentType = 'text';
      }
    },
    checkAnimation(e) {
      if(e.animationName === 'onAutoFillStart') {
        this.isAutofilled = true;
        this.inputFocusOut();
        this.$refs.input.blur();
      } else if(e.animationName === 'onAutoFillCancel') {
        this.isAutofilled = false;
      }
    },
  },
};
