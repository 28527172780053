export default [
  {
    name: 'веточка сакуры',
    value: 'sakura',
    price: 1500,
    description: 'Нежное сочетание холодно-горячего дыхания, поцелуев и покусываний по всему телу',
  },
  {
    name: 'боди',
    value: 'body',
    price: 1500,
    description: 'Страстные техники массажа, которые мастер выполняет грудью и ягодицами',
  },
  {
    name: 'киска',
    value: 'pussy',
    price: 2000,
    description: 'Возможность прикосновений к мастеру в зоне бикини',
  },
  {
    name: 'пип-шоу',
    value: 'peep-show',
    price: 2000,
    description: 'Мастер ласкает себя и доводит до полного расслабления',
  },
  {
    name: 'ролевая игра',
    value: 'roleplay',
    price: 2000,
  },
  {
    name: 'совместный душ',
    value: 'shower',
    price: 1000,
  },
  {
    name: 'массаж в 4 руки',
    value: '4hands',
    price: 4000,
  },
  {
    name: 'массаж простаты',
    value: 'prostate',
    price: 3000,
  },
  {
    name: 'доп. расслабление',
    value: 'additional relax',
    price: 2000,
  },
  {
    name: 'имитация позы 69',
    value: '69',
    price: 1500,
  },
  {
    name: 'имитация оральных ласк',
    value: 'oral',
    price: 1500,
  },
  {
    name: 'имитация позы наездница',
    value: 'rider',
    price: 1500,
  },
];