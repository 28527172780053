import { render, staticRenderFns } from "./request.vue?vue&type=template&id=88da80d6&"
import script from "./request.vue?vue&type=script&lang=js&"
export * from "./request.vue?vue&type=script&lang=js&"
import style0 from "./request.vue?vue&type=style&index=0&id=88da80d6&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseInput: require('/vercel/path0/src/components/base/input.vue').default,BaseSelect: require('/vercel/path0/src/components/base/select.vue').default,BaseButton: require('/vercel/path0/src/components/base/button.js').default,IconWa: require('/vercel/path0/src/components/icon/wa.vue').default,BaseSection: require('/vercel/path0/src/components/base/section.vue').default,IconCheck: require('/vercel/path0/src/components/icon/check.vue').default,BaseModal: require('/vercel/path0/src/components/base/modal.vue').default})
