
export default {
  name: 'icon-check',
  props: {
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 14,
    },
  },
  data() {
    return {
      defaultHeight: 14,
      defaultWidth: 18,
    };
  },
  computed: {
    viewBox() {
      return typeof this.width === 'string' ? `0 0 ${this.defaultWidth} ${this.defaultHeight}` : `0 0 ${this.width} ${this.height}`;
    },
  },
};
