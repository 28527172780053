export default [
  {
    id: 0,
    name: 'прелюдия',
    price: 5000,
    time: 60,
    description: '',
    programs: [
      'Классический массаж',
      'Эротика',
      'Лингам',
      'Одно расслабление',
    ],
    exclude: [],
  },
  {
    id: 1,
    name: 'розовый фламинго',
    price: 15000,
    time: 70,
    description: '2 мастера',
    programs: [
      'Веточка сакуры',
      'Лайт лесби-шоу',
      'Классический массаж',
      'Одно расслабление',
    ],
    exclude: [
      'sakura',
      '4hands',
    ],
  },
  {
    id: 2,
    name: 'hot lesbian',
    price: 25000,
    time: 70,
    description: '2 мастера',
    programs: [
      'Приват от двух мастеров',
      'Веточка сакуры с клубничкой',
      'Классический массаж',
      'Киска',
      'Боди',
      'Откровенное лесби-шоу с игрушкой',
      'Два расслабления',
    ],
    exclude: [
      'sakura',
      'pussy',
      'body',
      '4hands',
    ],
  },
  {
    id: 3,
    name: 'фут-фетиш',
    price: 8000,
    time: 60,
    description: '',
    programs: [
      'Приват танец',
      'Совместный душ',
      'Веточка сакуры в позе 69',
      'Фут-джоб',
      'Имитация оральных ласк',
      'Киска',
      'Классический массаж',
      'Одно расслабление',
    ],
    exclude: [
      'shower',
      'sakura',
      'oral',
      'pussy',
    ],
  },
  {
    id: 4,
    name: 'darkroom light',
    price: 15000,
    time: 70,
    description: '',
    programs: [
      'Ролевая игра',
      'Легкая доминация',
      'Бут-фетиш',
      'Контроль оргазма',
      'Киска',
      'Классический массаж',
      'Эротика',
      'Массаж простаты',
      'Плетка',
      'Два расслабления',
    ],
    exclude: [
      'roleplay',
      'prostate',
      'pussy',
    ],
  },
  {
    id: 5,
    name: 'darkroom hot',
    price: 25000,
    time: 90,
    description: '',
    programs: [
      'Ролевая игра',
      'Бут-фетиш',
      'Трамплинг',
      'Массаж простаты + плетка',
      'Классический массаж',
      'Киска',
      'Повязка на глаза, кляп и связывание',
      'Игра на контрастах (воск свечи, лед)',
      'Страпон',
      'Два расслабления',
    ],
    exclude: [
      'roleplay',
      'prostate',
      'pussy',
    ],
  },
  {
    id: 6,
    name: 'для пар',
    price: 15000,
    time: 90,
    description: '',
    programs: [
      'Приват',
      'Лайт лесби-шоу',
      'Киска',
      'Эротика',
      'Расслабление для мужчины и женщины',
      'Классический массаж, либо возможность оставить пару на 15-20 минут наедине',
      '+ 3000 мастер остается с парой на это время',
    ],
    exclude: [
      'pussy',
    ],
  },
  {
    id: 7,
    name: 'для девушек',
    price: 7000,
    time: 60,
    description: '',
    programs: [
      'Совместный душ с мастером',
      'Веточка сакуры',
      'Частичное боди',
      'Расслабляющий массаж',
      'Клубничка',
      'Массаж йони',
      'Киска',
      'Одно расслабление',
    ],
    exclude: [
      'shower',
      'sakura',
      'body',
      'pussy',
    ],
  },
  {
    id: 8,
    name: 'гейша',
    price: 10000,
    time: 60,
    description: '',
    programs: [
      'Массаж лица',
      'Ролевая игра (любовница или жена)',
      'Расслабление грейпфрутом',
      'Классический массаж',
      'Киска',
      'Поиск эрогенных зон',
      'Клубничка',
      'Повязка на глаза',
      'Имитация оральных ласк',
      'Одно расслабление',
    ],
    exclude: [
      'roleplay',
      'oral',
      'pussy',
    ],
  },
];